body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

header {
  position: fixed;
  z-index: 1000;
  height: 64px;
  display: flex;
  justify-content: center;
  width: 100%;
  transition: box-shadow 0.3s ease-out, background 0.3s ease-out;
}
header .headerWrapper {
  width: 70%;
  max-width: 1200px;
  height: 64px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
header .headerWrapper .logoBox {
  display: flex;
  align-items: center;
  font-size: 33px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #FFFFFF;
}
header .headerWrapper .logoBox img {
  height: 30px;
  margin-right: 8px;
}
header .phoneHeader {
  width: 82%;
  max-width: 100%;
  height: 10vw;
}
header .phoneHeader .logoBox {
  font-size: 4vw;
}
header .phoneHeader .logoBox img {
  height: 30px;
  margin-right: 2vw;
}
header .phoneHeader .nav-phone-menu {
  fill: #333333;
  width: 4vw;
  height: 4vw;
  cursor: pointer;
}
header .button {
  float: right;
  height: 100%;
  line-height: 64px;
  margin-left: 20px;
}
header .button .ant-btn {
  border-radius: 32px;
  font-size: 16px;
  line-height: 32px;
  height: 32px;
  color: #fff;
  border-width: 0;
  font-family: PingFang SC;
  background-color: transparent;
  box-shadow: 0 0 0;
}
header .button .ant-btn:hover {
  background-color: rgba(245, 191, 76, 0.7);
  color: #fff;
}
header .active-btn {
  float: right;
  height: 100%;
  line-height: 64px;
  margin-left: 20px;
}
header .active-btn .ant-btn {
  border-radius: 32px;
  font-size: 16px;
  line-height: 32px;
  height: 32px;
  color: #fff;
  border-width: 0;
  font-family: PingFang SC;
  background-color: #2BC0A1;
  box-shadow: 0 0 0;
}
header .active-btn .ant-btn:hover {
  background-color: rgba(245, 191, 76, 0.7);
  color: #fff;
}
header.show-shadow {
  background: #FDFDFD;
  box-shadow: 0 2px 8px rgba(229, 229, 229, 0.5);
}
header.btn-white {
  background-color: #FFF;
  box-shadow: 0 2px 8px rgba(229, 229, 229, 0.5);
}
header.btn-white .headerWrapper .logoBox {
  color: #2BC0A1;
}
header.btn-white .button .ant-btn {
  color: #4D4D4D;
}
header.btn-white .button .ant-btn:hover {
  background-color: rgba(43, 192, 161, 0.2);
  color: #2BC0A1;
}
@keyframes spin {
  0% {
    transform: rotate(-180deg);
  }
  45% {
    transform: rotate(-90deg);
  }
  70% {
    transform: rotate(-45deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
header.btn-white .active-btn {
  animation: spin 0.5s linear;
}
header.btn-white .active-btn .ant-btn {
  background-color: #2BC0A1;
}
header.btn-white .active-btn .ant-btn:hover {
  background-color: rgba(43, 192, 161, 0.2);
  color: #2BC0A1;
}
.popover-menu {
  width: 40vw;
  left: 27vw;
}
.popover-menu button.lang {
  margin: 16px auto;
  float: none;
}
.popover-menu div.version {
  margin: 32px auto 16px;
  float: none;
}
.popover-menu .ant-popover-inner {
  overflow: hidden;
}
.popover-menu .ant-popover-inner-content {
  padding: 0;
}
.popover-menu .ant-popover-content ul li {
  padding: 0;
}
.popover-menu .ant-popover-content ul li span {
  color: #2BC0A1;
}
.popover-menu .ant-popover-content ul li:hover {
  background: #2bc0a14d;
}
.popover-menu .ant-popover-content ul li::selection {
  background: #2BC0A1;
  color: #fff;
}
.popover-menu .ant-popover-content ul .ant-menu-item-selected {
  background: #2bc0a14d;
  color: #fff;
}
.popover-menu .ant-popover-content ul .ant-menu-item::after {
  border-right: 4px solid #2BC0A1;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors */
.text-center {
  text-align: center!important;
}
.text-left {
  text-align: left!important;
}
#reat-content {
  font-family: Roboto, PingFang SC, Helvetica Neue For Number, -apple-system, BlinkMacSystemFont, Segoe UI, Hiragino Sans GB, Microsoft YaHei, Helvetica Neue, Helvetica, Arial, sans-serif;
}
.intro .header {
  padding-top: 0;
  padding-left: 0;
  padding-right: 0;
}
h2,
h3,
h4,
h5,
h6 {
  font-weight: 400;
}
.btn {
  transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.page-wrapper {
  width: 100%;
  padding: 0;
  overflow: hidden;
}
.page-wrapper .page {
  width: 80%;
  max-width: 1200px;
  margin: auto;
  position: relative;
}
.page-wrapper .page h2 {
  margin-bottom: 30px;
  font-size: 2rem;
}
.page-wrapper .page .separator {
  margin-bottom: 65px;
  display: inline-block;
  width: 30px;
  height: 5px;
  background: #1890FF;
  border-radius: 2.5px;
}
.card {
  text-decoration: none;
}
.card .title,
.card .description {
  color: rgba(0, 0, 0, 0.65) !important;
  font-size: 14px;
  font-weight: 300;
}
.card:hover .title {
  color: rgba(0, 0, 0, 0.65) !important;
}
.features img {
  margin-top: 0 !important;
}
.page h2,
.page h4,
.page h5,
.page span,
.page p,
.page .feature,
.page .card-wrapper,
.page .main-info,
.page .detail {
  will-change: transform;
}
.page .main-info {
  line-height: 36px;
}
.banner {
  width: 100%;
  margin: auto;
  position: relative;
  height: 55.9vw;
  overflow: hidden;
  background: url(../../static/media/website-bg1.31fef938.png) center / auto 100% no-repeat;
}
.banner-wrapper {
  background: #f8fafe;
  min-height: 320px;
}
.banner > div {
  display: inline-block;
}
.banner-bg-img {
  width: 101%;
  height: 100vh;
  margin-left: -3px;
}
.banner-bg-word-wrapper {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  padding-top: 9.6vw;
  overflow: hidden;
  text-align: center;
}
.banner-word-main {
  width: 70%;
  margin: 0 auto;
  max-width: 1200px;
  display: flex;
  justify-content: space-between;
}
.banner-word-left {
  flex: 3 1;
  text-align: center;
}
.banner-word-left-title {
  padding: 0;
  margin: 0;
  text-align: left;
  font-size: 33px;
  font-family: PingFang SC;
  font-weight: 500;
  line-height: 46px;
  color: #FFFFFF;
}
.banner-word-left-subtitle {
  text-align: left;
  padding: 0;
  margin: 16px 0 16px 0;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 500;
  line-height: 20px;
  color: #FFFFFF;
}
.banner-word-left-desc {
  text-align: left;
  padding: 0;
  margin: 0;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 500;
  line-height: 20px;
  color: #FFFFFF;
}
.banner-word-download {
  margin-top: 53px;
  text-align: center;
  width: 235px;
  height: 44px;
  background: #F5BF4C;
  border-radius: 16px;
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 600;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 2px 3px 13px #BEEAE1;
}
.banner-word-download svg {
  width: 17px;
  height: 17px;
  fill: #fff;
  margin-right: 10px;
}
.banner-word-download:hover {
  background: rgba(245, 191, 76, 0.7);
  color: #fff;
}
.banner-word-img-wrapper {
  flex: 4 1;
  padding: 88px;
}
.banner-word-img-wrapper img {
  width: 417px;
  height: 336px;
}
.banner-img-wrapper {
  width: 60%;
  height: 100%;
}
.banner-text-wrapper {
  width: 40%;
  padding-right: 114px;
  height: 265px;
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 0;
}
.banner-text-wrapper h1 {
  font-size: 46px;
}
.banner-text-wrapper p {
  font-size: 16px;
  margin: 50px 0 60px;
  font-weight: 300;
}
.banner-md-img-wrapper {
  width: 100%;
  margin: 0;
  background: #f8fafe;
  position: relative;
  height: 360px;
  display: none;
}
.banner-md-img-wrapper img {
  width: 417px;
  height: 336px;
}
.phone-banner-wrapper {
  width: 100vw;
  background: #fff;
  background: url(../../static/media/phoneBannerBg.0a4177dd.png) 100% 100% / auto 100% no-repeat;
  padding-top: 9.5vw;
}
.phone-banner-wrapper .banner-title {
  width: 67.5vw;
  padding: 0;
  margin: 0 auto;
  font-size: 5.6vw;
  font-family: Roboto;
  font-weight: bold;
  line-height: 6.5vw;
  color: #FFFFFF;
  text-align: center;
}
.phone-banner-wrapper .banner-desc {
  padding: 0;
  margin: 4.7vw auto 1.9vw auto;
  width: 69.3vw;
  font-size: 3.3vw;
  font-family: Roboto;
  font-weight: 400;
  line-height: 4.4vw;
  color: #FFFFFF;
  text-align: center;
}
.phone-banner-wrapper .banner-more {
  padding: 0;
  margin: 0 auto;
  font-size: 3.3vw;
  font-family: Roboto;
  font-weight: 400;
  line-height: 3.9vw;
  color: #FFFFFF;
  text-align: center;
}
.phone-banner-wrapper .phone-download {
  margin: 6vw auto 0 auto;
  width: 45.1vw;
  height: 10.5vw;
  background: #F5BF4C;
  border-radius: 1.9vw;
  font-size: 3.3vw;
  font-family: Roboto;
  font-weight: bold;
  color: #FFFFFF;
  display: flex;
  align-items: center;
  justify-content: center;
}
.phone-banner-wrapper .phone-download svg {
  width: 3.7vw;
  height: 3.7vw;
  margin-right: 1.2vw;
}
.phone-banner-wrapper .banner-mobileBg {
  width: 100%;
  position: relative;
  bottom: 0;
}
.page1 {
  background: #fff;
  padding-bottom: 40px;
  overflow: hidden;
  display: flex;
  justify-content: center;
}
.page1 .page-main-content {
  padding-top: 30px;
  z-index: 3;
}
.page1 .page-main-content .fsTitle {
  padding: 0;
  margin: 0;
  font-size: 38px;
  font-family: PingFang SC;
  font-weight: 600;
  line-height: 38px;
  color: #2BC0A1;
  text-align: center;
}
.page1 .page-main-content .fsDesc {
  padding: 0;
  margin: 8px auto 40px auto;
  width: 580px;
  font-size: 22px;
  font-family: PingFang SC;
  font-weight: 400;
  line-height: 31px;
  color: #4D4D4D;
  text-align: center;
}
.page1 .one-row-page {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin: 0 auto;
  width: 900px;
}
.page1 .item-feature {
  width: 220px;
  text-align: center;
}
.page1 .item-feature .cardImg {
  margin: 0 auto;
  margin-bottom: 23px;
  width: 117px;
  height: 78px;
}
.page1 .item-feature .cardTitle {
  padding: 0;
  margin: 0 0 12px 0;
  font-size: 19px;
  font-family: PingFang SC;
  font-weight: 500;
  line-height: 26px;
  color: #F5BF4C;
  text-align: center;
}
.page1 .item-feature .cardDetail {
  padding: 0 ;
  margin: 0;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 400;
  line-height: 20px;
  color: #4D4D4D;
  text-align: center;
}
.page1 .card {
  transition: transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1), box-shadow 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  position: relative;
  color: #868e96;
  display: inline-block;
  max-width: 360px;
  height: 272px;
  padding: 32px;
  box-shadow: 0 2px 2px rgba(84, 48, 132, 0.06);
  margin: 0 auto;
  flex-direction: column;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  text-align: center;
  border-radius: 4px;
}
.page1 .card .card-img-top {
  width: 100%;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}
.page1 .card h3 {
  font-size: 30px;
}
.page1 .card img {
  height: 50px;
  margin: 20px 0;
}
.page1 .card:hover {
  text-decoration: none;
  transform: translateY(-12px);
  box-shadow: 0 12px 24px rgba(84, 48, 132, 0.06);
}
.page3 {
  width: 100%;
  height: 53vw;
  background: #fff;
  background: url(../../static/media/website-bg3.8a0d2b09.png) center 0 / auto 100% no-repeat;
  padding-top: 100px;
  position: relative;
  z-index: 0;
}
.page3 .page3-title {
  margin: 0;
  padding: 0;
  font-size: 38px;
  font-family: PingFang SC;
  font-weight: 600;
  line-height: 52px;
  color: #2BC0A1;
  text-align: center;
}
.page3 .page3-subtitle {
  margin: 18px auto 39px auto;
  padding: 0;
  font-size: 22px;
  font-family: PingFang SC;
  font-weight: 400;
  line-height: 31px;
  color: #4D4D4D;
  text-align: center;
}
.page3 .allFaq {
  margin: 60px auto 0 auto;
  width: 1100px;
  display: flex;
  flex-flow: wrap;
  justify-content: space-around;
}
.page3 .allFaq .one-card {
  width: 315px;
  height: 390px;
  background: #FFFFFF;
  box-shadow: 2px 3px 13px #BEEAE1;
  border-radius: 8px;
  padding: 40px 21px 0 21px;
}
.page3 .allFaq .one-card img {
  width: 55px;
  height: 62px;
}
.page3 .allFaq .one-card .card-title {
  padding: 0;
  margin: 15px 0 24px 0;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 600;
  line-height: 20px;
  color: #24368C;
  text-align: center;
}
.page3 .allFaq .one-card .caed-detail .one-item {
  margin: 8px 0 0 0;
  display: flex;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 400;
  line-height: 20px;
  color: #24368C;
  text-align: left;
}
.page3 .allFaq .one-card .caed-detail .one-item .value {
  flex: 1 1;
  margin-left: 5px;
}
.page3 .page3-mobile-content {
  width: 100%;
  padding: 0 30px;
  position: relative;
}
.page3 .page3-mobile-content .page3-title {
  padding: 0;
  margin: 0;
  font-size: 33px;
  font-family: Roboto;
  font-weight: bold;
  line-height: 40px;
  color: #3b3291;
  opacity: 1;
  text-align: center;
}
.page3 .page3-mobile-content .page3-subtitle {
  font-size: 20px;
  padding: 0;
  margin: 13px auto 0 auto;
  width: 80%;
  font-family: Roboto;
  font-weight: 400;
  line-height: 25px;
  color: #5e57a7;
  opacity: 1;
  text-align: center;
}
.page3 .page3-mobile-content .faqBgImg2 {
  position: absolute;
  width: 20%;
  top: 380px;
  right: 0px;
  z-index: -1;
}
.page3 .page3-mobile-content .faqBgImg3 {
  position: absolute;
  width: 30%;
  top: 800px;
  left: 0px;
  z-index: -1;
}
.page3 .page3-mobile-content .faqBgImg4 {
  position: absolute;
  width: 40%;
  top: 1280px;
  right: 0px;
  z-index: -1;
}
.page3 .page3-mobile-content .one-card {
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
  margin-top: 42px;
  background: #ffffff;
  box-shadow: 1px 3px 11px #e1dbff;
  opacity: 1;
  border-radius: 7px;
  padding: 20px;
}
.page3 .page3-mobile-content .one-card .faqBgImg {
  position: absolute;
  width: 30%;
  top: -160px;
  left: -30px;
  z-index: -1;
}
.page3 .page3-mobile-content .one-card .common-card {
  width: 90%;
  max-width: 365px;
  margin: 0 auto;
  text-align: center;
}
.page3 .page3-mobile-content .one-card .common-card img {
  margin: 0 auto;
  width: 85px;
}
.page3 .page3-mobile-content .one-card .last-card {
  max-width: 100%;
  width: 100%;
}
.page3 .page3-mobile-content .one-card .card-title {
  padding: 0;
  margin: 20px 0;
  font-size: 22px;
  font-family: Roboto;
  font-weight: bold;
  line-height: 29px;
  color: #3b3291;
  letter-spacing: 0px;
  opacity: 1;
  text-align: center;
}
.page3 .page3-mobile-content .one-card .card-detail p {
  padding: 0;
  margin: 0;
  font-size: 20px;
  font-family: Roboto;
  font-weight: 400;
  line-height: 30px;
  color: #5e57a7;
  letter-spacing: 0px;
  opacity: 1;
  text-align: left;
}
.page3 .page3-mobile-content .mobile-common-footer {
  margin-bottom: 25px;
}
.page3 .page3-mobile-content .mobile-common-footer .title {
  padding: 0;
  margin: 0;
  font-size: 25px;
  font-family: Roboto;
  font-weight: bold;
  line-height: 29px;
  color: #5e57a7;
  text-align: center;
}
.page3 .page3-mobile-content .mobile-common-footer .list-content {
  margin-top: 21px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.page3 .page3-mobile-content .mobile-common-footer .list-content .one-item {
  display: flex;
  margin-bottom: 21px;
  padding: 5px 11px;
  text-align: center;
  background: #f4f2ff;
  box-shadow: 1px 3px 11px #e1dbff;
  border-radius: 7px;
  cursor: pointer;
}
.page3 .page3-mobile-content .mobile-common-footer .list-content .one-item span {
  opacity: 1;
  font-size: 20px;
  font-family: Roboto;
  font-weight: 400;
  color: #5e57a7;
  margin-left: 10px;
}
.page3 .page3-mobile-content .mobile-common-footer .list-content .one-item svg {
  width: 25px;
  height: 25px;
  fill: #5E57A7;
  padding-top: 5px;
}
.page3 .page3-mobile-content .mobile-common-footer .list-content .one-item .facebookIcon {
  width: 28px;
  height: 28px;
  fill: #5E57A7;
}
.page3 .page3-mobile-content .mobile-common-footer .list-content .one-item .wenjianIcon {
  width: 25px;
  height: 25px;
  fill: #5E57A7;
}
.page3 .page3-mobile-content .mobile-common-footer .regulation {
  justify-content: center;
  flex-wrap: wrap;
}
.phone-feature-wrapper {
  width: 100%;
  padding-top: 4vw;
  background: #FFFFFF;
}
.phone-feature-wrapper .phone-feature-title {
  font-size: 4.4vw;
  font-family: Roboto;
  font-weight: bold;
  line-height: 5.3vw;
  color: #2BC0A1;
  text-align: center;
}
.phone-feature-wrapper .phone-feature-desc {
  margin-top: 1.9vw;
  margin-bottom: 9vw;
  font-size: 3.3vw;
  font-family: Roboto;
  font-weight: 400;
  line-height: 3.9vw;
  color: #4D4D4D;
  text-align: center;
}
.phone-feature-wrapper .phone-feature-list {
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  justify-content: center;
}
.phone-feature-wrapper .phone-feature-list .item-feature {
  width: 47vw;
  margin-bottom: 4.7vw;
  text-align: center;
  box-sizing: border-box;
}
.phone-feature-wrapper .phone-feature-list .item-feature .cardImg {
  width: 21vw;
  height: 14vw;
  margin-bottom: 4.7vw;
}
.phone-feature-wrapper .phone-feature-list .item-feature .cardTitle {
  padding: 0;
  margin: 0;
  font-size: 3.8vw;
  text-align: center;
  font-family: Roboto;
  font-weight: 400;
  line-height: 4.5vw;
  color: #F5BF4C;
}
.phone-feature-wrapper .phone-feature-list .item-feature .cardDetail {
  margin-top: 1.9vw;
  font-size: 3.3vw;
  font-family: Roboto;
  font-weight: 400;
  line-height: 3.8vw;
  color: #4D4D4D;
  text-align: center;
}
.phone-faq-wrapper {
  width: 100vw;
  height: 200vw;
  background: #fff;
  background: url(../../static/media/phoneFaqBg.2c162e22.png) center 0 / auto 100% no-repeat;
  padding-top: 9vw;
}
.phone-faq-wrapper .phone-faq-title {
  font-size: 4.4vw;
  font-family: Roboto;
  font-weight: bold;
  line-height: 5.3vw;
  color: #2BC0A1;
  text-align: center;
}
.phone-faq-wrapper .phone-faq-desc {
  margin-top: 1.9vw;
  font-size: 3.3vw;
  font-family: Roboto;
  font-weight: 400;
  line-height: 3.9vw;
  color: #4D4D4D;
  text-align: center;
}
.phone-faq-wrapper .phone-faq-list .one-card {
  margin: 4.7vw auto;
  width: 83vw;
  padding: 4.7vw 4vw 4.7vw 5.6vw;
  background: #FFFFFF;
  box-shadow: 1px 3px 11px #BEEAE1;
  border-radius: 1vw;
  text-align: center;
}
.phone-faq-wrapper .phone-faq-list .one-card img {
  width: 6.5vw;
  height: 7.3vw;
}
.phone-faq-wrapper .phone-faq-list .one-card .card-title {
  margin: 2.8vw 0;
  text-align: center;
  font-size: 3.3vw;
  font-family: Roboto;
  font-weight: bold;
  line-height: 3.9vw;
  color: #24368C;
}
.phone-faq-wrapper .phone-faq-list .one-card .caed-detail {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.phone-faq-wrapper .phone-faq-list .one-card .caed-detail .one-item {
  margin-bottom: 1vw;
  font-size: 3.3vw;
  font-family: Roboto;
  font-weight: 400;
  line-height: 3.9vw;
  color: #24368C;
  display: flex;
}
.phone-faq-wrapper .phone-faq-list .one-card .caed-detail .one-item .value {
  margin-left: 0.8vw;
  text-align: left;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors */
.footer-wrapper .footer-bar {
  width: 100%;
  height: 28vw;
  background: #fff;
  background: url(../../static/media/website-footerBg.df76c988.png) center 0 / auto 100% no-repeat;
}
.footer-wrapper .footer-content {
  width: 80vw;
  margin: 0 auto;
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  padding-top: 11vw;
  z-index: 2;
}
.footer-wrapper .footer-content .one-center h2 {
  padding: 0;
  margin: 0;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 400;
  text-align: left;
  color: rgba(255, 255, 255, 0.7);
  margin-bottom: 17px;
}
.footer-wrapper .footer-content .one-center div {
  margin: 0;
  display: flex;
  justify-content: flex-start;
  text-align: left;
  align-items: center;
}
.footer-wrapper .footer-content .one-center div svg {
  width: 17px;
  height: 17px;
  margin-right: 17px;
  fill: #fff;
}
.footer-wrapper .footer-content .one-center div p {
  text-align: left;
  margin: 0;
  padding: 0;
  max-width: 280px;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 500;
  line-height: 24px;
  color: #FFFFFF;
}
.footer-wrapper .footer-content .one-center div .iconzizhi {
  width: 17px;
  height: 17px;
  margin-right: 7px;
  fill: #fff;
}
.footer-wrapper .footer-content .one-center a {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 15px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #ffffff;
  margin: 0 0 2vw 0;
  padding: 0;
}
.footer-wrapper .footer-content .one-center a svg {
  width: 17px;
  height: 17px;
  margin-right: 5px;
  fill: #fff;
}
.footer-wrapper .bottom-bar {
  overflow: hidden;
  height: 65px;
  line-height: 65px;
  text-align: center;
  background: #E7F4F0;
  font-size: 12px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #4D4D4D;
}
.phone-footer-wrapper {
  background: #fff;
}
.phone-footer-wrapper .phone-footer-detail {
  padding: 9.8vw 9vw 6.2vw 9vw;
  display: flex;
  justify-content: space-around;
}
.phone-footer-wrapper .phone-footer-detail .followBox {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.phone-footer-wrapper .phone-footer-detail .followBox .detailTitle {
  font-size: 4.4vw;
  font-family: Roboto;
  font-weight: bold;
  line-height: 5.3vw;
  color: #F5BF4C;
  margin-bottom: 4.7vw;
}
.phone-footer-wrapper .phone-footer-detail .followBox .oneAccount {
  width: 23.2vw;
  height: 6.5vw;
  background: #F5BF4C;
  border-radius: 1.9vw;
  font-size: 3.3vw;
  font-family: Roboto;
  font-weight: 400;
  color: #FFFFFF;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 2.8vw;
}
.phone-footer-wrapper .phone-footer-detail .followBox .oneAccount .fIcon {
  width: 2.9vw;
  height: 2.9vw;
  fill: #fff;
  margin-right: 1vw;
}
.phone-footer-wrapper .phone-footer-detail .followBox .oneAccount .tIcon {
  width: 3.6vw;
  height: 3.6vw;
  fill: #fff;
  margin-right: 1vw;
}
.phone-footer-wrapper .phone-footer-detail .followBox .oneAccount .yIcon {
  width: 4.1vw;
  height: 4.1vw;
  fill: #fff;
  margin-right: 1vw;
}
.phone-footer-wrapper .phone-footer-detail .regulation {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.phone-footer-wrapper .phone-footer-detail .regulation .detailTitle {
  font-size: 4.4vw;
  font-family: Roboto;
  font-weight: bold;
  line-height: 5.3vw;
  color: #F5BF4C;
  margin-bottom: 4.7vw;
}
.phone-footer-wrapper .phone-footer-detail .regulation .oneRegu {
  width: 44vw;
  height: 6.5vw;
  background: #F5BF4C;
  border-radius: 1.9vw;
  font-size: 3.3vw;
  font-family: Roboto;
  font-weight: 400;
  color: #FFFFFF;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 2.8vw;
}
.phone-footer-wrapper .phone-footer-detail .regulation .oneReguLarge {
  width: 50vw;
}
.phone-footer-wrapper .phone-footer-bg {
  width: 100vw;
  height: 49.4vw;
  background: #fff;
  background: url(../../static/media/phoneFooterBg.cd88013b.png) center 0 / auto 100% no-repeat;
  padding-top: 24.5vw;
}
.phone-footer-wrapper .phone-footer-bg .emailBox .emailTitle {
  text-align: center;
  font-size: 4.4vw;
  font-family: Roboto;
  font-weight: bold;
  line-height: 5.3vw;
  color: #FFFFFF;
  margin-bottom: 3.3vw;
}
.phone-footer-wrapper .phone-footer-bg .emailBox .emailDetail {
  font-size: 3.3vw;
  font-family: Roboto;
  font-weight: 400;
  line-height: 3.9vw;
  color: #FFFFFF;
  display: flex;
  justify-content: center;
  align-items: center;
}
.phone-footer-wrapper .phone-footer-bg .emailBox .emailDetail .eIcon {
  width: 3.3vw;
  height: 3.3vw;
  fill: #fff;
  margin-right: 1vw;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors */
@media (min-width: 700px) and (max-width: 1000px) {
  .banner-wrapper .banner {
    position: relative;
    height: 670px;
  }
  .banner-wrapper .banner .banner-bg-img {
    width: 100%;
    height: 670px;
  }
  .banner-wrapper .banner .banner-word-main {
    flex-direction: column;
    justify-content: center;
  }
  .banner-wrapper .banner .banner-word-main .banner-word-img-wrapper {
    display: none;
  }
  .banner-wrapper .banner .banner-img-wrapper,
  .banner-wrapper .banner .banner-text-wrapper {
    width: 80%;
    margin: auto;
    display: block;
    position: relative;
  }
  .banner-wrapper .banner .banner-img-wrapper {
    height: auto;
    margin: 40px auto;
  }
  .banner-wrapper .banner .banner-text-wrapper {
    text-align: center;
    padding: 0;
  }
  .banner-wrapper .banner .banner-text-wrapper p {
    margin: 8% 0;
    line-height: 1.5em;
  }
  .banner-md-img-wrapper {
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }
  .page1 .page-main-content .one-row-page {
    width: 600px;
    justify-content: center;
    margin: 0 auto;
  }
  .page1 .page-main-content .one-row-page .item-feature {
    width: 300px;
    margin: 40px 0;
  }
  .page3 .allFaq {
    width: 100%;
  }
  .page3 .allFaq .one-card {
    width: 230px;
    height: auto;
    padding-bottom: 20px;
  }
}
@media only screen and (max-width: 1000px) {
  .footer-wrapper .footer-content {
    width: 80vw;
    padding-top: 8vw;
    padding-bottom: 10px;
  }
}
@media only screen and (max-width: 830px) {
  .footer-wrapper .footer-content {
    width: 100vw;
    padding-top: 8vw;
    padding-bottom: 10px;
  }
  .footer-wrapper .footer-content .one-center a {
    margin: 0 0 10px 0;
  }
}
@media only screen and (max-width: 768px) {
  header {
    height: 10vw;
  }
}

body {
  background: #E7F4F0;
}
.contentWrapper {
  background: rgba(255, 255, 255, 0.5);
  padding: 35px;
  width: 900px;
  margin: auto;
}
.contentWrapper h1,
.contentWrapper h2 {
  font-size: 24px;
  text-align: center;
  color: #333;
  font-weight: 500;
}
.contentWrapper p {
  text-align: left;
  text-indent: 30px;
  font-size: 18px;
  line-height: 30px;
  color: #666;
}
@media only screen and (max-width: 830px) {
  .contentWrapper {
    background: rgba(255, 255, 255, 0.85);
    padding: 20px;
    width: 100%;
    margin: auto;
    border: 0;
    box-sizing: border-box;
  }
  .contentWrapper h1,
  .contentWrapper h2 {
    font-size: 24px;
    text-align: center;
    color: #333;
    font-weight: 500;
  }
  .contentWrapper p {
    text-align: left;
    text-indent: 30px;
    font-size: 18px;
    line-height: 30px;
    color: #666;
  }
}

